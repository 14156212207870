
import { Component, Vue } from 'vue-property-decorator';
import BlueBorderedButton from '@/components/buttons/BlueBordered.vue';

@Component({
  components: { BlueBorderedButton }
})
export default class EmptyMyOffers extends Vue {
  handleClick(): void {
    this.$router.go(0);
  }
}
